import Swiper, { Navigation, Pagination, EffectFade } from 'swiper/swiper.esm.js';

Swiper.use([Navigation, Pagination, EffectFade])

new Swiper('.promo-swiper', {
    slidesPerView: 1,
    longSwipes: false,
    loop: true,
    resistance: false,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    speed: 0.8 * 1000,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
    }
})
